import { useCallback, useMemo, useRef, useState, TouchEvent } from "react"
import { keyframes } from "@emotion/react"
import { css } from "../../helpers/css"
import { motion } from "framer-motion"
import { Image } from "../../../../../../reactor"
import { server } from "../../../../../../server"
import { Component } from "../../../../../../packages/editing/Component"
import { colors } from "../../constants/colors"
import { Box } from "../base/Box"
import { Heading } from "../typography/Heading"
import { Body } from "../typography/Body"
import { Button } from "../buttons/Button"
import { responsiveBorderRadius, responsiveCss, scaleValue, useMediaQuery } from "../../helpers/css"
import { Flex } from "../base/Flex"
import { componentAnimations } from "../../constants/animation"
import { ResponsiveBackgroundImageCss } from "../base/ResponsiveImage"

const progress = keyframes({
    from: {
        width: "0%",
    },

    to: {
        width: "100%",
    },
})

/**
 * A carousel to highlight the products.
 */
export function HighlightCarousel(props: {
    /**
     * The slides to present in the carousel
     */
    slides: Slide[]

    /**
     * Whether the carousel should move on to the next slide automatically after a given period of
     * time that defaults to 5000 ms, and can also be configured with `timePerSlide`.
     */
    autoSlide?: boolean

    /**
     * The number of ms each page is visible. Defaults to 5000 ms.
     */
    timePerSlide?: number
}) {
    const timePerSlide = props.timePerSlide || 5000

    const [currentIndex, setCurrentIndex] = useState(0)
    const [isPaused, setIsPaused] = useState(false)
    const [panned, setPanned] = useState(0)
    const [isPanning, setIsPanning] = useState(false)

    // Used to temporary disable animations when resetting to index 0 from extra last slide
    const slideAnimationDisabled = useRef(false)
    const touchPanStartX = useRef<null | number>(null)
    const carouselRef = useRef<HTMLDivElement>(null)
    const carouselWidth = carouselRef.current?.getBoundingClientRect().width

    const currentTheme = useMemo(
        () => props.slides[currentIndex]?.theme,
        [currentIndex, props.slides]
    )

    const xOffset = useMemo(() => {
        return `-${currentIndex * 100}%`
    }, [currentIndex])

    const goToNext = useCallback(() => {
        slideAnimationDisabled.current = false
        if (currentIndex + 1 === props.slides.length) {
            setCurrentIndex(0)
        } else {
            setCurrentIndex(currentIndex + 1)
        }
    }, [currentIndex, props.slides.length])

    const handleTouchStart = useCallback(
        (e: TouchEvent) => {
            if (props.slides.length > 1) {
                setIsPanning(true)
                touchPanStartX.current = e.touches[0]?.clientX ?? null
                e.stopPropagation()
            }
        },
        [props.slides.length]
    )

    const handleTouchMove = useCallback(
        (e: TouchEvent) => {
            if (touchPanStartX.current) {
                // No need to update for changes < than a pixel, so floor before checking if moved
                const offset =
                    Math.floor((touchPanStartX.current - (e.touches[0]?.clientX ?? 0)) * -1) ?? null

                if (panned !== offset) {
                    setPanned(offset)
                }
            }
            e.stopPropagation()
        },
        [panned]
    )

    const handleTouchEnd = useCallback(() => {
        // If user has panned more than 1/3 of the carousel, switch to the next slide
        // in the direction they've panned towards

        // Reset restart variables
        slideAnimationDisabled.current = false

        if (
            carouselWidth &&
            panned < (carouselWidth / 3) * -1 &&
            currentIndex < props.slides.length - 1
        ) {
            setCurrentIndex(currentIndex + 1)
        } else if (carouselWidth && panned > carouselWidth / 3 && currentIndex > 0) {
            setCurrentIndex(currentIndex - 1)
        }

        // End of panning, reset state and ref variables
        setIsPanning(false)
        setPanned(0)
        touchPanStartX.current = null
    }, [carouselWidth, panned, currentIndex, props.slides.length])

    const isMobile = useMediaQuery("max", "sm")

    return (
        <>
            <div
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                onMouseEnter={() => {
                    setIsPaused(true)
                }}
                onMouseLeave={() => {
                    setIsPaused(false)
                }}
                css={css(
                    {
                        display: "flex",
                        overflow: "clip",
                        width: "100%",
                        flexWrap: "nowrap",
                        position: "relative",
                        touchAction: "pan-y",
                        userSelect: "none",
                        MozUserSelect: "none",
                        msUserSelect: "none",
                        WebkitTouchCallout: "none",
                        WebkitUserSelect: "none",
                    },
                    responsiveBorderRadius("lg")
                )}
                ref={carouselRef}
            >
                {props.slides.map((slide, index) => (
                    <motion.div
                        animate={{ transform: `translateX(${xOffset}) translateX(${panned}px)` }}
                        transition={
                            slideAnimationDisabled.current ||
                            isPanning ||
                            index < currentIndex - 1 ||
                            index > currentIndex + 1
                                ? { duration: 0 }
                                : componentAnimations.carousel
                        }
                        key={slide.id || index}
                        style={{
                            maxWidth: "100%",
                            flex: "0 0 100%",
                        }}
                    >
                        <Slide {...slide} />
                    </motion.div>
                ))}
                <div
                    css={css({
                        position: "absolute",
                        bottom: 10,
                        left: "50%",
                        transform: "translateX(-50%)",
                        display: "flex",
                    })}
                >
                    {props.slides.length > 1 &&
                        props.slides.map((_, index) => (
                            <motion.div
                                key={index}
                                onClick={() => setCurrentIndex(index)}
                                animate={{
                                    width:
                                        props.autoSlide && index === currentIndex
                                            ? isMobile
                                                ? 16
                                                : 56
                                            : 8,
                                }}
                                css={css({
                                    height: 8,
                                    backgroundColor: `${currentTheme === "dark" ? colors.grayWhite : colors.gray500}66`,
                                    borderRadius: 4,
                                    marginRight: 8,
                                    overflow: "hidden",
                                    ":last-of-type": { marginRight: 0 },
                                })}
                            >
                                {props.autoSlide && index === currentIndex && (
                                    <div
                                        onAnimationEnd={goToNext}
                                        css={css(
                                            {
                                                backgroundColor:
                                                    currentTheme === "dark"
                                                        ? colors.grayWhite
                                                        : colors.gray500,
                                                height: "100%",
                                            },
                                            responsiveCss("min", "sm", {
                                                animationName: props.autoSlide
                                                    ? progress
                                                    : undefined,
                                                animationDuration: `${timePerSlide}ms`,
                                                animationTimingFunction: "linear",
                                                animationPlayState: isPaused ? "paused" : "playing",
                                                borderTopRightRadius: 4,
                                                borderBottomRightRadius: 4,
                                            })
                                        )}
                                    />
                                )}
                            </motion.div>
                        ))}
                </div>
            </div>
        </>
    )
}

type Slide = {
    /**
     * Id for the slide
     */
    id?: string

    /**
     * The title of the section.
     */
    heading: string

    /**
     * The text to show in addition to the heading.
     */
    text: string

    /**
     * How to align the text.
     */
    textAlign: "left" | "center" | "right"

    /**
     * The color theme to use for the slide. Light or dark.
     */
    theme: "light" | "dark"

    /**
     * Cover ensures the background image covers the entire carousel.
     * Auto makes it full height on desktop and full width on mobile.
     */
    imageSize: "auto" | "cover"

    /**
     * How to align the image in the carousel.
     */
    imageAlign?: number

    /**
     * Call to action for this slide. Renders a button with a text that links to either a page or URL.
     */
    cta?: {
        text: string
        href?: string
    }

    /**
     * Image that is rendered as a background image. Either as a cover for slides with
     * text align center, or to the opposite side of the text for text align left or right.
     */
    image?: Image
}

function Slide(props: Slide) {
    return (
        <Box
            as="section"
            color={props.theme === "dark" ? "grayWhite" : "gray500"}
            backgroundColor={props.theme === "dark" ? "gray500" : "sandLight"}
            borderRadius="lg"
            css={css(
                {
                    position: "relative",
                    flexDirection: "column",
                    height: 400,
                    maxHeight: "100%",
                    overflow: "hidden",
                    display: "flex",
                    flex: "1 0 100%",
                    justifyContent: alignToFlex(props.textAlign),
                },

                responsiveCss("min", "sm", {
                    flexDirection: props.textAlign === "right" ? "row-reverse" : "row",
                }),
                responsiveCss("min", "lg", {
                    height: scaleValue(600),
                })
            )}
        >
            <div
                css={css(
                    {
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundPosition: `center ${props.imageSize === "auto" ? "left" : "right"} ${props.imageAlign}%`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: props.imageSize === "cover" ? "cover" : "auto 100%",
                    },
                    responsiveCss("max", "xs", {
                        top: "40%",
                        backgroundPosition: `bottom left ${props.imageAlign}%`,
                    }),
                    props.image
                        ? ResponsiveBackgroundImageCss({ image: props.image, width: 1200 })
                        : undefined
                )}
            />
            <Box
                css={css(
                    {
                        flex: "1 1 0%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: props.textAlign,
                        zIndex: 1,
                    },
                    responsiveCss("min", "sm", {
                        alignItems: alignToFlex(props.textAlign),
                        justifyContent: "center",
                    }),
                    props.textAlign === "center" && {
                        justifyContent: "center",
                    }
                )}
            >
                <Flex
                    direction="column"
                    alignItems={alignToFlex(props.textAlign)}
                    css={css(
                        {
                            textAlign: "center",
                            alignItems: "center",
                            padding: scaleValue(56),
                            paddingBottom: 0,
                        },
                        responsiveCss("min", "sm", {
                            padding: "56px 96px",
                            maxWidth: scaleValue(540),
                        }),
                        responsiveCss("min", "sm", {
                            textAlign: props.textAlign,
                            alignItems: alignToFlex(props.textAlign),
                            padding: 0,
                            paddingLeft:
                                props.textAlign === "center" || props.textAlign === "right"
                                    ? 0
                                    : 80,
                            paddingRight:
                                props.textAlign === "center" || props.textAlign === "left" ? 0 : 80,
                            maxWidth: scaleValue(360),
                        }),
                        responsiveCss("min", "md", {
                            paddingLeft:
                                props.textAlign === "center" || props.textAlign === "right"
                                    ? 0
                                    : scaleValue(100),
                            paddingRight:
                                props.textAlign === "center" || props.textAlign === "left"
                                    ? 0
                                    : scaleValue(100),
                            maxWidth: scaleValue(props.textAlign === "center" ? 400 : 540),
                        }),
                        responsiveCss("min", "lg", {
                            textAlign: props.textAlign,
                            alignItems: alignToFlex(props.textAlign),
                            padding: 0,
                            paddingLeft:
                                props.textAlign === "center" || props.textAlign === "right"
                                    ? 0
                                    : scaleValue(120),
                            paddingRight:
                                props.textAlign === "center" || props.textAlign === "left"
                                    ? 0
                                    : scaleValue(120),
                            maxWidth: scaleValue(540),
                        })
                    )}
                >
                    <Heading level={1} margin={{ bottom: scaleValue(24) }}>
                        {props.heading}
                    </Heading>
                    <Body size="lg" margin={{ bottom: scaleValue(24) }}>
                        {props.text}
                    </Body>
                    {props.cta ? (
                        <Button size="md" variant="primary" href={props.cta.href}>
                            {props.cta.text}
                        </Button>
                    ) : null}
                </Flex>
            </Box>
            {props.textAlign !== "center" && (
                <Box
                    css={css(
                        {
                            flex: "1 1 0%",
                            overflow: "visible",
                            display: "flex",
                            position: "relative",
                            zIndex: 0,
                        },
                        responsiveCss("min", "sm", {
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                        }),
                        responsiveCss("max", "xs", {
                            position: "absolute",
                            height: "50%",
                            width: "100%",
                            bottom: 0,
                        })
                    )}
                ></Box>
            )}
        </Box>
    )
}

Component(HighlightCarousel, {
    name: "HighlightCarousel",
    gallery: {
        items: [
            {
                title: "Two slides, with auto slide",
                variants: [
                    {
                        props: {
                            autoSlide: true,
                            slides: [
                                {
                                    heading: "Eierskap møter sirkulær innovasjon",
                                    text: "Nyt den ultimate fleksibiliteten og lei de nyeste enhetene for så lite som 120 kr/md.",
                                    image: `${server()}/static/redoit/carousel-highlight-iphone.png` as any as Image,
                                    textAlign: "left",
                                    theme: "dark",
                                    cta: {
                                        text: "Begynn å lease",
                                        href: "/",
                                    },
                                    imageSize: "auto",
                                },
                                {
                                    heading: "Eierskap møter sirkulær innovasjon",
                                    text: "Nyt den ultimate fleksibiliteten og lei de nyeste enhetene for så lite som 120 kr/md.",
                                    image: `${server()}/static/redoit/carousel-highlight-light-iphones.png` as any as Image,
                                    textAlign: "center",
                                    theme: "light",
                                    imageSize: "auto",
                                },
                            ],
                        },
                    },
                ],
            },
            {
                title: "Single slide",
                variants: [
                    {
                        props: {
                            slides: [
                                {
                                    heading: "Eierskap møter sirkulær innovasjon",
                                    text: "Nyt den ultimate fleksibiliteten og lei de nyeste enhetene for så lite som 120 kr/md.",
                                    image: `${server()}/static/redoit/carousel-highlight-light-iphones.png` as any as Image,
                                    textAlign: "center",
                                    theme: "light",
                                    cta: {
                                        text: "Begynn å lease",
                                        href: "/",
                                    },
                                    imageSize: "auto",
                                },
                            ],
                        },
                    },
                ],
            },
            {
                title: "Background with right side focus",
                variants: [
                    {
                        props: (state = { left: 0 }, setState) => ({
                            slides: [
                                {
                                    heading: "Eierskap møter sirkulær innovasjon",
                                    text: "Nyt den ultimate fleksibiliteten og lei de nyeste enhetene for så lite som 120 kr/mnd.",
                                    image: `${server()}/static/redoit/bannerbilde.png` as any as Image,
                                    textAlign: "left",
                                    imageAlign: state.left,
                                    theme: "light",
                                    cta: {
                                        text: "Begynn å lease",
                                        url: "/order",
                                    },
                                } as any as Slide,
                            ],
                        }),
                        render: (cmp, state = { left: 0 }, setState) => (
                            <div>
                                <div style={{ display: "flex", marginBottom: 12 }}>
                                    <label style={{ marginRight: 8 }}>Alignment:</label>
                                    <input
                                        type="range"
                                        min="0"
                                        max="100"
                                        value={state.left}
                                        onChange={(e) =>
                                            setState({ left: parseInt(e.target.value) })
                                        }
                                    />
                                </div>
                                {cmp}
                            </div>
                        ),
                    },
                ],
            },
            {
                title: "Two slides, no auto slide",
                variants: [
                    {
                        props: {
                            slides: [
                                {
                                    heading: "Eierskap møter sirkulær innovasjon",
                                    text: "Nyt den ultimate fleksibiliteten og lei de nyeste enhetene for så lite som 120 kr/md.",
                                    image: `${server()}/static/redoit/carousel-highlight-iphone.png` as any as Image,
                                    textAlign: "left",
                                    theme: "dark",
                                    imageAlign: 90,
                                    cta: {
                                        text: "Begynn å lease",
                                        href: "/",
                                    },
                                    imageSize: "auto",
                                },
                                {
                                    heading: "Eierskap møter sirkulær innovasjon",
                                    text: "Nyt den ultimate fleksibiliteten og lei de nyeste enhetene for så lite som 120 kr/md.",
                                    image: `${server()}/static/redoit/carousel-highlight-light-iphones.png` as any as Image,
                                    textAlign: "center",
                                    theme: "light",
                                    imageSize: "auto",
                                },
                            ],
                        },
                    },
                ],
            },
        ],
    },
})

function alignToFlex(align: "left" | "right" | "center") {
    return align === "left" ? "flex-start" : align === "right" ? "flex-end" : "center"
}
