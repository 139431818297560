import { css } from "@emotion/react"
import dayjs from "dayjs"
import { MustacheString } from "../../../../packages/editing/Mustache"
import { Section } from "../../../../packages/editing/Section"
import advancedFormat from "dayjs/plugin/advancedFormat"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { Localized } from "../../../../packages/localization/Localized"
import { useLogOutWithVipps } from "../../../../packages/oidc/code-flow/VippsLogin"
import { Page } from "../../model/Page"
import { useMe, usePhoneOrders, useStoredPaymentMethods } from "../client"
import { Flex } from "../ui/components/base/Flex"
import { ResponsiveImage } from "../ui/components/base/ResponsiveImage"
import { Button } from "../ui/components/buttons/Button"
import { Divider } from "../ui/components/other/Divider"
import { Heading } from "../ui/components/typography/Heading"
import { Text } from "../ui/components/typography/Text"
import { Fragment, useState } from "react"
import { Modal } from "../ui/components/modal/Modal"
import { MarkdownView } from "../ui/components/base/MarkdownView"
import { Uuid } from "../../../../reactor"
import { responsiveCss, scaleValue } from "../ui/helpers/css"
import { Icon } from "../ui/components/visual/Icon"
import { useWebPageInfo } from "../../../../studio/client"
import { monthsShort } from "../ui/constants/text"
import { colors } from "../ui/constants/colors"

Section(AccountOverview)
function AccountOverview(section: {
    /**
     * Account dashboard headline. Available variables are `{{givenName}}` and `{{familyName}}`.
     * @default '{"no": "Kontooversikt"}'
     */
    heading: Localized<string>

    /**
     * Text below the headline. Available variables are `{{givenName}}` and `{{familyName}}`.
     * @default '{"no": "Hei {{givenName}}. Her finner du oversikten over dine abonnementer."}'
     */
    subheading: Localized<string>

    activeSubscriptions: ActiveSubscriptionsProps
    paymentMethods: PaymentMethodsProps
}) {
    const me = useMe()
    const localize = useLocalize()
    const logOutWithVipps = useLogOutWithVipps()

    dayjs.extend(advancedFormat)

    return (
        <Flex
            justifyContent="center"
            css={css(
                { paddingTop: 32, paddingBottom: 32 },
                responsiveCss("min", "md", { paddingTop: 64, paddingBottom: 64 })
            )}
        >
            <div
                style={{ width: "100%" }}
                css={css(
                    {
                        display: "grid",
                        gridTemplateAreas: '"text details" "menu details"',
                        gridTemplateColumns: "56fr 70fr",
                        gridTemplateRows: "minmax(0, auto)",
                        columnGap: scaleValue(140),
                    },
                    responsiveCss("max", "sm", {
                        gridTemplateAreas: '"text" "details" "menu"',
                        gridTemplateRows: "auto",
                        gridTemplateColumns: "1fr",
                        rowGap: 32,
                    }),
                    responsiveCss("min", "lg", { paddingLeft: 96, paddingRight: 96 })
                )}
            >
                <Flex direction="column" gap={16} style={{ gridArea: "text", minWidth: 0 }}>
                    <Heading level={1}>
                        {MustacheString(localize(section.heading), {
                            givenName: me.data?.givenName ?? "",
                            familyName: me.data?.familyName ?? "",
                        })}
                    </Heading>
                    <Text variant="body" size="lg" color="gray400">
                        {MustacheString(localize(section.subheading), {
                            givenName: me.data?.givenName ?? "",
                            familyName: me.data?.familyName ?? "",
                        })}
                    </Text>
                </Flex>
                <Flex
                    gap={28}
                    direction="column"
                    margin={{ top: scaleValue(48) }}
                    style={{ gridArea: "menu", minWidth: 0 }}
                >
                    <Flex
                        style={{ cursor: "pointer" }}
                        justifyContent="space-between"
                        alignItems="center"
                        onClick={async () => {
                            await logOutWithVipps()
                            window.location.href = "/"
                        }}
                    >
                        <Text variant="heading" level="3">
                            {localize({ no: "Logg ut", en: "Sign out" })}
                        </Text>
                        <Icon icon="arrowRight" />
                    </Flex>
                </Flex>
                <Flex direction="column" style={{ gridArea: "details", minWidth: 0 }}>
                    <ActiveSubscriptions {...section.activeSubscriptions} />
                    <PaymentMethods {...section.paymentMethods} />
                </Flex>
            </div>
        </Flex>
    )
}

type ActiveSubscriptionsProps = {
    /**
     * @default '{"no": "Dine abonnementer"}'
     */
    heading: Localized<string>

    /**
     * The subscription details page.
     */
    detailsPage: Page["id"]

    /**
     * @default '{"no": "Kansellert", "en": "Cancelled"}'
     */
    cancelledLabelText: Localized<string>

    /**
     * @default '{"no": "Se detaljer", "en": "See details"}'
     */
    detailsButtonText: Localized<string>
}

function ActiveSubscriptions(props: ActiveSubscriptionsProps) {
    const orders = usePhoneOrders()
    const localize = useLocalize()
    const [descriptionModalIsOpen, setDescriptionModalIsOpen] = useState<
        Uuid<"PhoneOrder"> | undefined
    >()

    const detailsPageInfo = useWebPageInfo(props.detailsPage)

    if (!orders.data?.orders.length) return <></>

    return (
        <>
            <Heading level={3} margin={{ bottom: scaleValue(24) }}>
                {localize(props.heading)}
            </Heading>
            <Flex gap={scaleValue(24)} direction="column">
                {orders.data.orders.map((order) => (
                    <Flex
                        key={order.id.valueOf()}
                        backgroundColor="gray100"
                        as="a"
                        href={
                            detailsPageInfo.data?.slug
                                ? `/${localize(detailsPageInfo.data.slug)}?id=${order.id.valueOf()}`
                                : ""
                        }
                        direction="column"
                        borderRadius="md"
                    >
                        <Flex
                            css={css(
                                { padding: scaleValue(24), gap: scaleValue(16) },
                                responsiveCss("min", "md", {
                                    padding: scaleValue(40),
                                    gap: scaleValue(32),
                                })
                            )}
                        >
                            <div
                                css={css(
                                    { width: scaleValue(72), flex: "0 0 auto" },
                                    responsiveCss("min", "md", { width: scaleValue(120) })
                                )}
                            >
                                {!!order.phone.images?.[0] && (
                                    <ResponsiveImage
                                        image={order.phone.images[0]}
                                        width={120}
                                        style={{ width: "100%" }}
                                    />
                                )}
                            </div>
                            <div style={{ flexGrow: 1 }}>
                                <Flex
                                    direction="column"
                                    css={css({ gap: 16 }, responsiveCss("min", "md", { gap: 24 }))}
                                >
                                    <div
                                        css={css({
                                            display: "grid",
                                            gridTemplateAreas:
                                                order.model.features &&
                                                order.model.features.length > 0
                                                    ? '"heading button" "features button"'
                                                    : '"heading button"',
                                            gridTemplateColumns: "1fr auto",
                                            gridTemplateRows: "auto auto",
                                        })}
                                    >
                                        <Flex style={{ gridArea: "heading" }} alignItems="center">
                                            <Heading level={4}>
                                                {localize(order.model.name)}
                                            </Heading>
                                        </Flex>
                                        {order.model.features &&
                                            order.model.features.length > 0 && (
                                                <Flex
                                                    gap={8}
                                                    alignItems="center"
                                                    style={{
                                                        gridArea: "features",
                                                        overflow: "hidden",
                                                    }}
                                                >
                                                    <Text
                                                        variant="body"
                                                        size="sm"
                                                        color="gray300"
                                                        css={css({
                                                            display: "inline-block",
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            flex: "1 1 auto",
                                                        })}
                                                    >
                                                        {order.model.features.map(
                                                            (feature, index) => (
                                                                <Fragment
                                                                    key={feature.id.valueOf()}
                                                                >
                                                                    {index > 0 && (
                                                                        <Text
                                                                            variant="body"
                                                                            size={[
                                                                                "lg",
                                                                                ["min", "lg", "xl"],
                                                                            ]}
                                                                            color="gray300"
                                                                            css={css({
                                                                                display:
                                                                                    "inline-block",
                                                                                marginLeft: 8,
                                                                                marginRight: 8,
                                                                            })}
                                                                        >
                                                                            ·
                                                                        </Text>
                                                                    )}
                                                                    {localize(feature.text)}
                                                                </Fragment>
                                                            )
                                                        )}
                                                    </Text>
                                                </Flex>
                                            )}
                                        <Flex style={{ gridArea: "button" }} alignItems="center">
                                            <Button
                                                size={["xs", ["min", "md", "sm"]]}
                                                iconStart="chevronDown"
                                                variant="light"
                                                onClick={(e) => {
                                                    setDescriptionModalIsOpen(order.id)
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                }}
                                            />
                                        </Flex>
                                        {order.model.description && (
                                            <Modal
                                                header={{
                                                    icon: "device",
                                                    title: localize({
                                                        no: "Produktdetaljer",
                                                        en: "Product details",
                                                    }),
                                                    closeButton: true,
                                                }}
                                                isOpen={descriptionModalIsOpen === order.id}
                                                onClose={() => setDescriptionModalIsOpen(undefined)}
                                            >
                                                <Text variant="body" size="sm">
                                                    <MarkdownView
                                                        value={localize(order.model.description)}
                                                    />
                                                </Text>
                                            </Modal>
                                        )}
                                    </div>
                                    <div css={responsiveCss("max", "sm", { display: "none" })}>
                                        <Divider horizontal spacing={0} />
                                    </div>
                                    <Flex direction="column" gap={4}>
                                        <div
                                            style={{
                                                display: "inline-flex",
                                                color: order.cancelled ? colors.gray300 : undefined,
                                            }}
                                        >
                                            <Flex
                                                alignItems="baseline"
                                                gap={4}
                                                css={
                                                    order.cancelled
                                                        ? css({
                                                              position: "relative",
                                                              ":after": {
                                                                  position: "absolute",
                                                                  content: '""',
                                                                  width: "100%",
                                                                  borderBottom: "1px solid",
                                                                  top: "56%",
                                                              },
                                                          })
                                                        : undefined
                                                }
                                            >
                                                <Text variant="heading" level="3">
                                                    {order.rentalPeriods[0]?.monthlyPrice.total.majorUnits.valueOf()}
                                                </Text>
                                                <Text variant="body" size="sm">
                                                    {order.rentalPeriods[0]?.monthlyPrice.total.currency.toLowerCase()}
                                                    /{localize(monthsShort)}
                                                </Text>
                                            </Flex>
                                        </div>
                                        {order.nextChargeDate ? (
                                            <Text variant="body" size="sm">
                                                {localize({
                                                    no: "Neste forfall",
                                                    en: "Lease due date",
                                                })}
                                                :{" "}
                                                {dayjs(order.nextChargeDate).format("Do MMMM YYYY")}
                                            </Text>
                                        ) : null}
                                        {order.cancelled && (
                                            <Flex gap={4} alignItems="center">
                                                <Icon icon="warning" color="brand" />
                                                <Text variant="body" size="sm" color="brand">
                                                    {localize(props.cancelledLabelText)}
                                                </Text>
                                            </Flex>
                                        )}
                                    </Flex>
                                </Flex>
                            </div>
                        </Flex>
                        <Divider horizontal spacing={0} />
                        <Flex padding={scaleValue(24)} justifyContent="flex-end">
                            <Button variant="dark" size="sm">
                                {localize(props.detailsButtonText)}
                            </Button>
                        </Flex>
                    </Flex>
                ))}
            </Flex>
        </>
    )
}

type PaymentMethodsProps = {
    /**
     * @default '{"no": "Dine betalingsmetoder"}'
     */
    heading: Localized<string>
}
function PaymentMethods(props: PaymentMethodsProps) {
    const localize = useLocalize()
    const paymentOptions = useStoredPaymentMethods()

    if (!paymentOptions.data?.length) return <></>

    return (
        <>
            <Heading level={3} margin={{ top: 32, bottom: 16 }}>
                {localize(props.heading)}
            </Heading>
            <Flex direction="column" gap={24}>
                {paymentOptions.data.map((paymentOption) => (
                    <Flex
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                        }}
                        borderColor="gray200"
                        borderRadius="md"
                        padding="md"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        {paymentOption.brand === "visa" ? <Visa /> : paymentOption.brand}
                        <Flex gap={24}>
                            <div style={{}}>**** **** **** {paymentOption.lastFour}</div>
                            <div style={{}}>
                                {paymentOption.expiryMonth}/{paymentOption.expiryYear}
                            </div>
                        </Flex>
                    </Flex>
                ))}
            </Flex>
        </>
    )
}

function Visa() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 324.68" width={48} height={32}>
            <path
                fill="#1434cb"
                d="m651.19.5c-70.93,0-134.32,36.77-134.32,104.69,0,77.9,112.42,83.28,112.42,122.42,0,16.48-18.88,31.23-51.14,31.23-45.77,0-79.98-20.61-79.98-20.61l-14.64,68.55s39.41,17.41,91.73,17.41c77.55,0,138.58-38.57,138.58-107.66,0-82.32-112.89-87.54-112.89-123.86,0-12.91,15.5-27.05,47.66-27.05,36.29,0,65.89,14.99,65.89,14.99l14.33-66.2S696.61.5,651.18.5h0ZM2.22,5.5L.5,15.49s29.84,5.46,56.72,16.36c34.61,12.49,37.07,19.77,42.9,42.35l63.51,244.83h85.14L379.93,5.5h-84.94l-84.28,213.17-34.39-180.7c-3.15-20.68-19.13-32.48-38.68-32.48,0,0-135.41,0-135.41,0Zm411.87,0l-66.63,313.53h81L494.85,5.5h-80.76Zm451.76,0c-19.53,0-29.88,10.46-37.47,28.73l-118.67,284.8h84.94l16.43-47.47h103.48l9.99,47.47h74.95L934.12,5.5h-68.27Zm11.05,84.71l25.18,117.65h-67.45l42.28-117.65h0Z"
            />
        </svg>
    )
}
