import { ImgHTMLAttributes } from "react"
import { css } from "@emotion/react"
import { Image, ImageOptions, ImageToUrl } from "../../../../../../reactor"

type ResponsiveImage = {
    image: Image
    width: number
    format?: ImageOptions["format"]
}

/**
 * Component that renders an img with srcSet generated based on predefined pixel densities.
 */
export function ResponsiveImage(props: ImgHTMLAttributes<HTMLImageElement> & ResponsiveImage) {
    const { image, width, ...rest } = props
    return (
        <img
            srcSet={[1, 1.5, 2, 3]
                .map(
                    (x) =>
                        `${ImageToUrl(image, { width: Math.ceil(width * x), format: props.format })} ${x}x`
                )
                .join(", ")}
            src={ImageToUrl(props.image, { width })}
            {...rest}
        />
    )
}

export function ResponsiveBackgroundImageCss(props: ResponsiveImage) {
    return css(
        [1, 1.5, 2, 3].map((x) => ({
            [`@media (min-resolution: ${x}dppx)`]: {
                backgroundImage: `url('${ImageToUrl(props.image, { width: Math.ceil(props.width * x), format: props.format })}')`,
            },
        }))
    )
}
