import {
    createContext,
    ReactNode,
    RefObject,
    useCallback,
    useEffect,
    useMemo,
    useState,
    MouseEvent,
    useRef,
    UIEvent,
} from "react"
import { createPortal } from "react-dom"
import { css, responsiveSpacing } from "../../helpers/css"
import { springAnimations } from "../../constants/animation"
import { Component } from "../../../../../../packages/editing/Component"
import { AnimatePresence, motion, useAnimate } from "framer-motion"
import { responsiveCss, scaleValue } from "../../helpers/css"
import { Icon, IconName } from "../visual/Icon"
import { Heading } from "../typography/Heading"
import { Flex } from "../base/Flex"
import { Button } from "../buttons/Button"
import { lipsum } from "../../helpers/lipsum"
import { Body } from "../typography/Body"
import { colors } from "../../constants/colors"
import { useLocalize } from "../../../../../../packages/localization/client-side/useLocalize"
import { Text } from "../typography/Text"
import { percentageHexValues } from "../../constants/opacity"

type StepTransition = "next" | "prev" | undefined

type Step = {
    /**
     * Useful for linking directly to a step.
     */
    id?: string

    /**
     * Header config for the step.
     */
    header?: {
        title?: string
        closeButton?: boolean
        backButton?: boolean
        icon?: IconName
    }

    /**
     * @reflection any
     */
    render: (next: () => void, prev: () => void) => ReactNode
}

type CloseReason = "escapeKeyDown" | "backdropClick" | "closeButton"
type CloseEvent = MouseEvent | KeyboardEvent

export function Modal(props: {
    size?: "md" | "lg"
    width?: number | string
    isOpen?: boolean
    onClose?: (e: CloseEvent, reason: CloseReason) => void
    onBack?: () => void
    onOpenAnimationEnd?: () => void
    onCloseAnimationEnd?: () => void

    /**
     * Texts for prompt to show if user attempts to close modal.
     */
    closePrompt?: {
        heading: string
        text: string
        cancelButtonText: string
        confirmButtonText: string
    }

    /**
     * Callback for when step changes.
     */
    onStepChange?: (index: number) => void

    header?: {
        title?: string
        closeButton?: boolean
        backButton?: boolean
        icon?: IconName
        divider?: boolean
    }

    /**
     * Control index of current step.
     */
    step?: number

    /**
     * Utilize built in steps management in the modal.
     */
    steps?: Step[]

    /**
     * Render function to use in combination with steps that allows for combining step content and
     * non step content, and also provides setStep function.
     * @reflection any
     */
    renderSteps?: (
        children: ReactNode,
        steps: ReactNode,
        setStep: (index: number) => void
    ) => ReactNode

    /**
     * @reflection any
     */
    children?: ReactNode
}) {
    const size = props.size ?? "md"
    const localize = useLocalize()
    const backdropRef = useRef<HTMLDivElement>(null)

    const [currentStep, setCurrentStep] = useState(props.step ?? 0)
    const [stepTransition, setStepTransition] = useState<"next" | "prev" | undefined>()
    const [promptClose, setPromptClose] = useState<CloseReason | undefined>()

    const handlePrevStep = useCallback(() => {
        setStepTransition("prev")
        setCurrentStep(currentStep - 1)
        props.onStepChange?.(currentStep - 1)
    }, [currentStep, props])

    const handleNextStep = useCallback(() => {
        setStepTransition("next")
        setCurrentStep(currentStep + 1)
        props.onStepChange?.(currentStep + 1)
    }, [currentStep, props])

    const handleBack = useCallback(() => {
        handlePrevStep()
        if (typeof props.onBack === "function") props.onBack()
    }, [props, handlePrevStep])

    const handleClose = useCallback(
        (e: CloseEvent, reason: CloseReason) => {
            if (props.closePrompt && !promptClose) setPromptClose(reason)
            else props.onClose?.(e, reason)
        },
        [props, promptClose]
    )

    const handleKeydown = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                if (promptClose) setPromptClose(undefined)
                else handleClose(e, "escapeKeyDown")
            }
        },
        [promptClose, handleClose]
    )

    useEffect(() => {
        if (typeof window !== "undefined") {
            if (props.isOpen) {
                document.body.style.overflow = "hidden"
            } else {
                document.body.style.overflow = ""
            }
        }
    }, [props.isOpen])

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("keydown", handleKeydown)
            return () => {
                window.removeEventListener("keydown", handleKeydown)
            }
        }
    }, [handleKeydown])

    useEffect(() => {
        if (!props.isOpen) {
            setPromptClose(undefined)
        }
    }, [props.isOpen])

    const [scrollContainerRef, scrollContainerAnimate] = useAnimate<HTMLDivElement>()
    const [overflowTop, setOverflowTop] = useState(false)
    const [overflowBottom, setOverflowBottom] = useState(false)

    const handleScroll = useCallback(
        (e: UIEvent<HTMLDivElement>) => {
            if (e.currentTarget.scrollTop === 0 && overflowTop) {
                setOverflowTop(false)
            } else if (e.currentTarget.scrollTop > 0 && !overflowTop) {
                setOverflowTop(true)
            }

            const hasScrolledToBottom =
                Math.ceil(e.currentTarget.scrollTop + e.currentTarget.clientHeight) ===
                e.currentTarget.scrollHeight
            if (hasScrolledToBottom && overflowBottom) {
                setOverflowBottom(false)
            } else if (!hasScrolledToBottom && !overflowBottom) {
                setOverflowBottom(true)
            }
        },
        [overflowBottom, overflowTop]
    )

    const icon = useMemo(
        () => props.steps?.[currentStep]?.header?.icon ?? props.header?.icon,
        [currentStep, props.header?.icon, props.steps]
    )

    const closePromptRef = useRef<HTMLDivElement>(null)
    const minHeightForClosePrompt = useMemo(() => {
        if (promptClose) {
            return closePromptRef.current?.getBoundingClientRect().height
        }
    }, [promptClose])

    if (typeof window === "undefined") return <></>

    return createPortal(
        <ModalContext.Provider value={{ scrollContainerRef, scrollContainerAnimate }}>
            <AnimatePresence>
                {props.isOpen ? (
                    <motion.div
                        key="backdrop"
                        style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            height: "100%",
                            width: "100%",
                            zIndex: 100,
                            opacity: 0,
                            backgroundColor: "rgba(0, 0, 0, 0.32)",
                        }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onClick={(e) => handleClose(e, "backdropClick")}
                    />
                ) : null}
                {props.isOpen ? (
                    <div
                        ref={backdropRef}
                        key="container"
                        style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 200,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-start",
                        }}
                        css={containerCss}
                        onClick={(e) => {
                            if (e.target === backdropRef.current) handleClose(e, "backdropClick")
                        }}
                    >
                        <Flex
                            key="modal"
                            motion={{
                                transition: springAnimations["200"],
                                variants: {
                                    open: { transform: "translateY(0px)", scale: 1, opacity: 1 },
                                    close: { opacity: 0 },
                                },
                                initial: { transform: "translateY(50px)", scale: 0.75, opacity: 0 },
                                animate: "open",
                                exit: "close",
                                onAnimationComplete: (definition) => {
                                    if (definition === "open" && props.onOpenAnimationEnd) {
                                        props.onOpenAnimationEnd()
                                    } else if (definition === "close") {
                                        props.onCloseAnimationEnd?.()
                                    }
                                },
                            }}
                            direction="column"
                            backgroundColor="grayWhite"
                            borderRadius="lg"
                            elevation
                            style={{
                                width: props.width ?? "auto",
                                minHeight: minHeightForClosePrompt,
                            }}
                            css={modalCss}
                        >
                            {
                                <div
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        opacity: promptClose ? "100" : "0",
                                        zIndex: promptClose ? "100" : "-1",
                                    }}
                                >
                                    <Flex
                                        ref={closePromptRef}
                                        direction="column"
                                        gap={24}
                                        style={{ zIndex: "10001" }}
                                        css={css(
                                            {
                                                padding: "var(--modal-padding)",
                                            },
                                            responsiveCss("min", "md", {
                                                width: "80%",
                                            })
                                        )}
                                        motion={{
                                            initial: { opacity: 0, translateY: "8px" },
                                            animate: promptClose
                                                ? { opacity: 1, translateY: "0px" }
                                                : undefined,
                                            transition: springAnimations["200"],
                                        }}
                                    >
                                        <Heading level={2}>{props.closePrompt?.heading}</Heading>
                                        <Text>{props.closePrompt?.text}</Text>
                                        <Flex
                                            gap={8}
                                            justifyContent="flex-end"
                                            css={css(
                                                responsiveCss("max", "sm", {
                                                    flexDirection: "column",
                                                    justifyContent: "stretch",
                                                })
                                            )}
                                        >
                                            <Button
                                                variant="secondary"
                                                size="sm"
                                                onClick={() => setPromptClose(undefined)}
                                            >
                                                {props.closePrompt?.cancelButtonText}
                                            </Button>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={(e) =>
                                                    // Should always be called because close prompt
                                                    // will not be visible unless promptClose is set.
                                                    promptClose
                                                        ? handleClose(e, promptClose)
                                                        : undefined
                                                }
                                            >
                                                {props.closePrompt?.confirmButtonText}
                                            </Button>
                                        </Flex>
                                    </Flex>
                                    <motion.div
                                        initial={{
                                            opacity: 0,
                                            backdropFilter: "blur(20px)",
                                            WebkitBackdropFilter: "blur(20px)",
                                        }}
                                        animate={{
                                            opacity: 1,
                                            backdropFilter: "blur(40px)",
                                            WebkitBackdropFilter: "blur(40px)",
                                            transition: springAnimations["200"],
                                        }}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            backgroundColor: `${colors.grayWhite}${percentageHexValues["50"]}`,
                                            zIndex: 10000,
                                        }}
                                    ></motion.div>
                                </div>
                            }

                            {props.header ? (
                                <Flex
                                    justifyContent="space-between"
                                    style={
                                        size === "lg"
                                            ? {
                                                  paddingBottom: 24,
                                                  marginBottom: 24,
                                                  borderBottom: `1px solid ${colors.gray200}`,
                                              }
                                            : { marginBottom: 24 }
                                    }
                                    css={
                                        props.header.divider
                                            ? css(
                                                  { borderBottom: `1px solid ${colors.gray200}` },
                                                  responsiveSpacing("sm", "padding-bottom")
                                              )
                                            : undefined
                                    }
                                >
                                    <Flex alignItems="center">
                                        <AnimatePresence mode="popLayout">
                                            {props.steps?.[currentStep]?.header?.backButton ??
                                            props.header.backButton ? (
                                                <motion.div
                                                    initial={{ opacity: 0 }}
                                                    animate={{ opacity: 1 }}
                                                    exit={{ opacity: 0 }}
                                                >
                                                    <Button
                                                        key="backButton"
                                                        iconStart="arrowLeft"
                                                        variant="secondary"
                                                        size="sm"
                                                        onClick={handleBack}
                                                        margin={{ right: scaleValue(32) }}
                                                        aria-label={localize({
                                                            no: "Tilbake",
                                                            en: "Back",
                                                        })}
                                                    />
                                                </motion.div>
                                            ) : null}
                                        </AnimatePresence>
                                        <AnimatePresence mode="popLayout">
                                            <motion.div
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0 }}
                                            >
                                                <Flex alignItems="center">
                                                    {icon ? (
                                                        <Icon
                                                            icon={icon}
                                                            size={[24, ["min", "md", 32]]}
                                                            css={css(
                                                                { marginRight: 8 },
                                                                responsiveCss("min", "md", {
                                                                    marginRight: 12,
                                                                })
                                                            )}
                                                        />
                                                    ) : null}
                                                    {props.steps?.[currentStep]?.header?.title ??
                                                    props.header.title ? (
                                                        <Heading level={2}>
                                                            {props.steps?.[currentStep]?.header
                                                                ?.title ?? props.header.title}
                                                        </Heading>
                                                    ) : null}
                                                </Flex>
                                            </motion.div>
                                        </AnimatePresence>
                                    </Flex>
                                    <AnimatePresence>
                                        {props.header.closeButton ? (
                                            <motion.div
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0 }}
                                            >
                                                <Button
                                                    key="closeButton"
                                                    iconStart="close"
                                                    variant="secondary"
                                                    size="sm"
                                                    onClick={(e) => handleClose(e, "closeButton")}
                                                    margin={{ left: scaleValue(40) }}
                                                    aria-label={localize({
                                                        no: "Lukk denne popupen",
                                                        en: "Close this popup",
                                                    })}
                                                />
                                            </motion.div>
                                        ) : null}
                                    </AnimatePresence>
                                </Flex>
                            ) : null}
                            {!props.steps && <OverflowGradient top show={overflowTop} />}
                            <div
                                style={{
                                    overflowY: props.steps ? "hidden" : "auto",
                                    maxHeight: "100%",
                                    flex: "1 1 auto",
                                    display: "flex",
                                    flexDirection: props.steps ? "row" : "column",
                                    ...(props.steps
                                        ? {
                                              width: "calc(100% + calc(var(--modal-padding) * 2))",
                                              transform:
                                                  "translateX(calc(var(--modal-padding) * -1))",
                                          }
                                        : {}),
                                }}
                                ref={props.steps ? null : scrollContainerRef}
                                onScroll={props.steps ? undefined : handleScroll}
                            >
                                {props.steps && props.renderSteps ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            width: "100%",
                                            paddingLeft: "var(--modal-padding)",
                                            paddingRight: "var(--modal-padding)",
                                        }}
                                    >
                                        {props.renderSteps(
                                            <AnimatePresence
                                                initial={false}
                                                mode="popLayout"
                                                custom={stepTransition}
                                            >
                                                <motion.div
                                                    key={`step${currentStep}`}
                                                    initial="initial"
                                                    animate="target"
                                                    exit="exit"
                                                    style={{
                                                        flex: "1 0 100%",
                                                    }}
                                                    variants={{
                                                        initial: (st: StepTransition) =>
                                                            !st
                                                                ? {}
                                                                : {
                                                                      opacity: 0,
                                                                      translateX:
                                                                          st === "next"
                                                                              ? "100px"
                                                                              : "-100px",
                                                                  },
                                                        target: (st: StepTransition) =>
                                                            !st
                                                                ? {}
                                                                : {
                                                                      opacity: 1,
                                                                      translateX: "0px",
                                                                  },
                                                        exit: (st: StepTransition) =>
                                                            st
                                                                ? {
                                                                      translateX:
                                                                          st === "next"
                                                                              ? "-100px"
                                                                              : "100px",
                                                                      opacity: 0,
                                                                  }
                                                                : {},
                                                    }}
                                                    custom={stepTransition}
                                                >
                                                    <OverflowGradient top show={overflowTop} />
                                                    <div
                                                        ref={scrollContainerRef}
                                                        onScroll={handleScroll}
                                                        style={{
                                                            overflowY: "scroll",
                                                            maxHeight: "100%",
                                                            overflowX: "visible",
                                                        }}
                                                        css={responsiveCss("min", "md", {
                                                            paddingRight: 20,
                                                        })}
                                                    >
                                                        {props.steps[
                                                            props.step ?? currentStep
                                                        ]?.render(handleNextStep, handlePrevStep)}
                                                    </div>
                                                    <OverflowGradient
                                                        bottom
                                                        show={overflowBottom}
                                                    />
                                                </motion.div>
                                            </AnimatePresence>,
                                            props.children,
                                            setCurrentStep
                                        )}
                                    </div>
                                ) : (
                                    props.children
                                )}
                            </div>
                            {!props.steps && <OverflowGradient bottom show={overflowBottom} />}
                        </Flex>
                    </div>
                ) : (
                    <></>
                )}
            </AnimatePresence>
        </ModalContext.Provider>,
        document.body
    )
}

function OverflowGradient(
    props: { top: boolean; show: boolean } | { bottom: boolean; show: boolean }
) {
    return (
        <div
            css={css({
                position: "relative",
                "&:before": {
                    content: '""',
                    position: "absolute",
                    ["top" in props ? "top" : "bottom"]: 0,
                    width: "100%",
                    height: 12,
                    background: `linear-gradient(${"top" in props ? "0" : "180"}deg, rgba(255, 255,255,0) 10%, rgba(255, 255,255,.8) 80%, rgba(255, 255,255,1))`,
                    opacity: props.show ? 1 : 0,
                    transition: "all ease-in-out 200ms",
                    zIndex: 10,
                    pointerEvents: "none",
                },
            })}
        />
    )
}

const containerCss = css(
    {
        "--container-padding": "16px",
        padding: "var(--container-padding)",
    },
    responsiveCss("min", "md", {
        "--container-padding": "64px",
    })
)

const modalCss = css(
    {
        position: "relative",
        overflow: "hidden",
        backgroundColor: colors.grayWhite,
        maxHeight: "calc(100svh - var(--container-padding) - var(--container-padding))",
        "--modal-padding": "16px",
        padding: "var(--modal-padding)",
    },
    responsiveCss("min", "sm", {
        "--modal-padding": "24px",
    }),
    responsiveCss("min", "md", {
        maxWidth: 960,
        "--modal-padding": "40px",
    })
)

export const ModalContext = createContext<{
    scrollContainerRef?: RefObject<HTMLDivElement>
    scrollContainerAnimate?: (...args: any) => void
}>({})

Component(Modal, {
    name: "Modal",
    gallery: {
        path: "Modal/Modal",
        items: [
            {
                variants: [
                    {
                        props: (state = { isOpen: true }, setState) => ({
                            isOpen: state.isOpen,
                            onClose: () => setState({ isOpen: false }),
                            header: {
                                title: "Trade-in",
                                icon: "handCoins" as IconName,
                                closeButton: true,
                            },
                            children: (
                                <div>
                                    <Heading level={4} margin={{ bottom: 8 }}>
                                        {lipsum(3)}
                                    </Heading>
                                    <Body size="md" margin={{ bottom: 32 }}>
                                        {lipsum(100)}
                                    </Body>
                                    <Heading level={4} margin={{ bottom: 8 }}>
                                        {" "}
                                        {lipsum(3)}{" "}
                                    </Heading>
                                    <Body size="md" margin={{ bottom: 32 }}>
                                        {" "}
                                        {lipsum(100)}{" "}
                                    </Body>
                                    <Heading level={4} margin={{ bottom: 8 }}>
                                        {" "}
                                        {lipsum(3)}{" "}
                                    </Heading>
                                    <Body size="md" margin={{ bottom: 32 }}>
                                        {" "}
                                        {lipsum(100)}{" "}
                                    </Body>
                                    <Heading level={4} margin={{ bottom: 8 }}>
                                        {lipsum(3)}
                                    </Heading>
                                    <Body size="md" margin={{ bottom: 32 }}>
                                        {lipsum(100)}
                                    </Body>
                                    <Heading level={4} margin={{ bottom: 8 }}>
                                        {lipsum(3)}
                                    </Heading>
                                    <Body size="md">{lipsum(100)} </Body>
                                </div>
                            ),
                        }),
                        render: (cmp, _, setState) => (
                            <div>
                                <button
                                    onClick={() => {
                                        setState({ isOpen: true })
                                    }}
                                >
                                    Open modal
                                </button>
                                {cmp}
                            </div>
                        ),
                    },
                ],
            },
        ],
    },
})
