import { ComponentProps, Fragment, ReactNode } from "react"
import { Outlet, Route, Routes } from "react-router-dom"
import { Global, SerializedStyles } from "@emotion/react"
import { LocalesContext } from "../../localization/client-side/useLocalesContext"
import { ModalProvider } from "../../modal/Modal"
import { Gallery } from "../../../gallery/Gallery"
import { Studio } from "../../../studio/Studio"
import { useDefaultLocale, useLocales, useWebPermissions } from "../../../studio/client"
import { EditableSiteLazy } from "../../editing/EditableSiteLazy"
import { CookieConsentView } from "../../cookie-consent/CookieConsentView"
import { Login } from "../../../studio/Login"
import { WebPage } from "./WebPage"
import { EditableSections } from "../../editing/EditableSections"
import { CurrentLocaleContext } from "../../localization/client-side/CurrentLocaleContext"
import { WebError } from "./WebError"
import { useSessionState } from "../../../reactor/Web"

/**
 * TODO: Probably want studio and gallery by default, so maybe flip to `noGallery` and `noStudio`
 * or something.
 */
export function WebSite(config: {
    /**
     * Set to true if WebSite should have a component gallery.
     */
    gallery?: boolean

    /**
     * Set to true if WebSite should have studio.
     */
    studio?: boolean

    /**
     * A function returning a rendered Login component to use custom login page.
     */
    renderLogin?: () => ReactNode

    /**
     * Global styles that will be applied to the entire site including gallery and studio.
     */
    globalStyles?: SerializedStyles

    /**
     * Props to forward to each EditableSection.
     */
    EditableSectionsProps?: Partial<Omit<ComponentProps<typeof EditableSections>, "sections">>

    /**
     * A function returning a custom CookieConsentView, for sites who want to override this.
     */
    renderCookieConsentView?: () => ReactNode

    /**
     * Include routes or other children
     */
    children?: ReactNode

    /**
     * A custom error screen to use when a page is not found, or another fatal
     * error occurs during rendering.
     */
    errorScreen?: typeof WebError

    pageComponent?: typeof WebPage
}) {
    const ErrorScreen = config.errorScreen ?? WebError

    const { data: locales } = useLocales()
    const { data: permissions } = useWebPermissions()
    const defaultLocale = useDefaultLocale().data?.defaultLocale
    const [hideEnvironmentSticker, setHideEnvironmentSticker] = useSessionState(
        "hideEnvironmentSticker",
        false
    )

    const PageComponent = config.pageComponent ?? WebPage

    if (!locales || !permissions || !defaultLocale) return null

    const env = permissions.environment

    return (
        <>
            {env !== "production" && !hideEnvironmentSticker ? (
                <div
                    onClick={() => {
                        alert(
                            "This will hide the environment sticker for this session. Close the tab and reopen to see it again."
                        )
                        setHideEnvironmentSticker(true)
                    }}
                    style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        backgroundColor: env === "staging" ? "yellow" : "skyblue",
                        width: 90,
                        zIndex: 1000,
                        textAlign: "center",
                        transform: "translate(-25%,70%) rotate(-45deg)",
                        fontSize: 12,
                    }}
                >
                    {env.toUpperCase()}
                </div>
            ) : null}
            <ModalProvider>
                {config.globalStyles ? <Global styles={config.globalStyles} /> : null}
                <LocalesContext.Provider value={locales}>
                    <Routes>
                        {config.studio && Studio()}
                        {config.gallery && Gallery()}
                        <Route
                            path="/login"
                            element={config.renderLogin ? config.renderLogin() : <Login />}
                        />
                        <Route
                            element={
                                <>
                                    <EditableSiteLazy condition={!!permissions.canEditWebsite} />
                                </>
                            }
                        >
                            {locales.map((l) =>
                                l.key === defaultLocale ? (
                                    <Route
                                        key={l.key.valueOf()}
                                        element={
                                            <CurrentLocaleContext.Provider
                                                value={{
                                                    get locale() {
                                                        return l.key
                                                    },
                                                }}
                                            >
                                                {config.renderCookieConsentView?.() ?? (
                                                    <CookieConsentView />
                                                )}
                                                <Outlet />
                                            </CurrentLocaleContext.Provider>
                                        }
                                    >
                                        <Fragment key={l.key.toString()}>
                                            <Route
                                                index
                                                element={
                                                    <PageComponent
                                                        errorScreen={ErrorScreen}
                                                        EditableSectionsProps={
                                                            config.EditableSectionsProps
                                                        }
                                                        locale={l.key}
                                                    />
                                                }
                                            />
                                            <Route
                                                path="/:slug"
                                                element={
                                                    <PageComponent
                                                        errorScreen={ErrorScreen}
                                                        locale={l.key}
                                                        EditableSectionsProps={
                                                            config.EditableSectionsProps
                                                        }
                                                    />
                                                }
                                            />
                                        </Fragment>
                                    </Route>
                                ) : (
                                    <Route
                                        key={l.key.valueOf()}
                                        element={
                                            <CurrentLocaleContext.Provider
                                                value={{
                                                    get locale() {
                                                        return l.key
                                                    },
                                                }}
                                            >
                                                {config.renderCookieConsentView?.() ?? (
                                                    <CookieConsentView />
                                                )}
                                                <Outlet />
                                            </CurrentLocaleContext.Provider>
                                        }
                                    >
                                        <Fragment key={l.key.toString()}>
                                            <Route
                                                index
                                                path={`/${l.key.toString()}`}
                                                element={
                                                    <PageComponent
                                                        errorScreen={ErrorScreen}
                                                        EditableSectionsProps={
                                                            config.EditableSectionsProps
                                                        }
                                                        locale={l.key}
                                                    />
                                                }
                                            />
                                            <Route
                                                path={`/${l.key.toString()}/:slug`}
                                                element={
                                                    <PageComponent
                                                        errorScreen={ErrorScreen}
                                                        EditableSectionsProps={
                                                            config.EditableSectionsProps
                                                        }
                                                        locale={l.key}
                                                    />
                                                }
                                            />
                                        </Fragment>
                                    </Route>
                                )
                            )}
                            {config.children}

                            <Route
                                path="*"
                                element={
                                    <ErrorScreen
                                        error={{
                                            status: 404,
                                            type: "Not found",
                                            detail: "No page exists at this path",
                                        }}
                                    />
                                }
                            />
                        </Route>
                    </Routes>
                </LocalesContext.Provider>
            </ModalProvider>
        </>
    )
}
